<template>
  <div class="content-container">
    <PageTitle :title="$t('FAQ.TITLE')" class="page-title">
      <template #extra-content>
        <BasicButton :button-text="$t('FAQ.CREATE')" @click="createQuestion()"/>
      </template>
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('FAQ.OVERVIEW') }}</h1>

    <CustomTable
      :options="faqTableOptions"
      :table-data="faqData.items"
      :pagination-data="faqData"
      :search-function="manageSearch"
      :filter-data="filterData"
      :on-reorder="(id,newIndex) => reorderFaq(id,newIndex)"
      :search-bar-options="searchText"
      :empty-state-data="emptyStateData">
      <template #question="props">
        <div>{{ props.rowData.question }}</div>
      </template>
      <template #faqCategoryId="props">
        <div>{{ categoryLookup(props.rowData.faqCategoryId).name }}</div>
      </template>
      <template #createdAt="props">
        <div>{{ new Date(props.rowData.createdAt).toLocaleDateString('nl') + ' ' + new Date(props.rowData.createdAt).toLocaleTimeString('nl') }}</div>
      </template>
      <template #isActivated="props">
        <div v-if="props.rowData.isActivated" class="published status"> {{ $t("PUBLISHED").toUpperCase() }} </div>
        <div v-else class="concept status">{{ $t("CONCEPT").toUpperCase() }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group gap">
          <ActionButton :text="$t('DELETE')"
                        :icon="require('../../../assets/icons/icn_trash_white.svg')"
                        custom-class="button-red"
                        @click="deleteQuestion(props.rowData)"/>
          <ActionButton :text="$t('EDIT')"
                        :icon="require('../../../assets/icons/icn_pencil_white.svg')"
                        custom-class="button-blue"
                        @click="editQuestion(props.rowData)"/>
        </div>
      </template>
      <template #right-side-info>
        <p> <b> {{ faqData.totalItems || 0 }} </b>{{ $t('FAQ.TOTAL_QUESTIONS') }} </p>
      </template>
    </CustomTable>
    <BasicModal v-if="isBasicVersionDisplayed"/>
  </div>

</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'

import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, FAQ_TABLE } from '@/utils/helpers/customTable/CustomTableHelper'
import { computed, ref } from '@vue/reactivity'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import ActionButton from '@/components/table/ActionButton.vue'
import nl from '@/utils/language/nl.json'
import { useStore } from 'vuex'
import { GET_FAQS, DELETE_FAQ, REORDER_FAQ } from '@/store/modules/cms/actions'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { useRouter } from 'vue-router'
import { createEmptyTableData } from '@/utils/helpers/customTable/EmptyTableHelper'
import BasicButton from '@/components/elements/basic/BasicButton'

export default {
  name: 'Faq',
  components: {
    PageTitle,
    CustomTable,
    ActionButton,
    BasicModal,
    BasicButton
  },
  setup() {
    const modalController = ref(new ModalController())
    const store = useStore()
    const router = useRouter()

    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_FAQ
    )

    const faqData = computed(() => store.getters.getFaqs)


    const emptyStateData = createEmptyTableData('FAQ', createQuestion, require('@/assets/icons/icn_faq.svg'))


    const searchText = {
      searchId: 1,
      placeholder: nl.FAQ.SEARCH
    }

    const categories = [{ id: 1, name: 'Algemeen' }, { id: 2, name: 'Mijn MediaMasters' }, { id: 3, name: 'Technisch' }, { id: 4, name: 'Account' }]

    function categoryLookup(id) {
      return categories.find(value => value.id === id)
    }

    //This should be got from the api aswell in the future.
    const filterData = computed(() => {
      return {
        placeholder: 'Filter op categorie',
        singleSelection: false,
        dropdownOptions: [
          {
            name: 'Categorie',
            type: 'faqCategoryId',
            options: categories.map(cat => {
              cat.keyName = cat.name
              return cat })
          },
          {
            name: 'Top 10',
            type: 'isTopTen',
            options: [{ id: 1, name: 'Opgenomen in top 10' }]
          }
        ]
      }
    })

    function manageSearch(payload) {
      getFaqs(payload)
    }

    function getFaqs(payload) {
      store.dispatch(GET_FAQS, payload)
    }


    function deleteQuestion(question) {
      modalController.value.setModal(BASIC_MODAL_CONTENT.DELETE_FAQ(question.question), () => postDeleteQuestion(question.id), null, null, MODAL_NAMES.DELETE_FAQ)
    }

    function postDeleteQuestion(question) {
      store.dispatch(DELETE_FAQ, question).then(response => {
        if (response) {
          modalController.value.resetActiveModal()
          getFaqs({})
        }
      })
    }

    function editQuestion(question) {
      router.push({ name: ROUTE_NAMES_CMS.EDIT_FAQ, params: { id: question.id } })
    }

    function createQuestion() {
      router.push({ name: ROUTE_NAMES_CMS.CREATE_FAQ })
    }

    function reorderFaq(id, newIndex) {
      const payload = {
        /*questionId: questionId,
        id: id,
        questionIndex: newIndex*/
      }
      store.dispatch(REORDER_FAQ, payload).then(response => {
        getFaqs()
      })
    }

    return {
      faqTableOptions: getTableOptions(FAQ_TABLE),
      faqData,
      filterData,
      manageSearch,
      deleteQuestion,
      editQuestion,
      createQuestion,
      emptyStateData,
      ROUTE_NAMES_CMS,
      isBasicVersionDisplayed,
      searchText,
      reorderFaq,
      categoryLookup
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";


.status{
  font-weight: 800;
}

.published{
  color: rgb(81,84,104)
}

.concept{
 color: rgb(123,150,179)
}

.gap{
  display: flex;
  gap: 10px
}

</style>
